import { createRef, useContext, useState } from 'react';

// import components
import MLModal from 'common/ml-components/MLModal';
import MLTabForm from 'common/ml-components/MLTabForm';
import CourseOptions from './components/CourseOptions';
import Languages from './components/LanguageOptions';
import QuizOptions from './components/QuizOptions';

// import redux
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { saveCourseOptions, saveLanguageCollection } from 'store/slices/courses';

// import react-hook-form
import { SubmitHandler } from 'react-hook-form';

// import react-router
import { useNavigate, useParams } from 'react-router-dom'; 

// import models
import { IMLTab } from 'common/ml-components/MLTabForm/MLTabForm';
import { GamificationPointDto, LanguageStatusDto, LessonTypeDto, SupportedLanguageDto } from 'api-client';

// import helpers
import _ from "lodash";
import { getLanguageFromCode, timeout } from 'utils/helpers/helpers';

// import contexts
import { LoadingContext } from 'contexts/loading-context';
import Gamification from './components/Gamification';
import { DateTime, Duration } from 'luxon';

interface ICourseConfigurationProps {
  show: boolean;
  onDismiss: () => void;
}

export interface ICourseOptionsFormData {
  title: string;
  description: string;
  expirationDate: Date | null;
  validFromDate: Date | null;
  isSearchEnabled: boolean;
}

export interface IQuizOptionsFormData {
  isFinalQuizRepeatable: boolean;
  passingPercentage: number;
}

export interface ILanguagesCollection {
  collection: { id: string; value: string, active: boolean }[];
  newLanguage: string;
}

interface IGamificationInfo {
  'courseCompletitionBonusPoints'?: number | null;
  'oneShotExamPassingBonusPoints'?: number | null;
  'courseCompletitionPoints'?: GamificationPointDtoWithDates | null;
}

type GamificationPointDtoWithDates = Omit<GamificationPointDto, 'targetCompletitionDate' | 'totalMaxPoints' | 'minimumPoints'> & {
  'targetCompletitionDate'?: Date | null;
  'totalMaxPoints'?: number | null;
  'minimumPoints'?: number | null;
};

export interface ICourseConfigurationsFormData {
  courseOptions: ICourseOptionsFormData;
  quizOptions: IQuizOptionsFormData;
  languages: ILanguagesCollection;
  gamification: IGamificationInfo;
}

const CourseConfigurations: React.FC<ICourseConfigurationProps> = ({ show, onDismiss }) => {
  const title = useSelector((state: RootState) => state.courses.course?.title);
  const description = useSelector((state: RootState) => state.courses.course?.description);
  const validFromDate = useSelector((state: RootState) => state.courses.course?.validFromDate);
  const expirationDate = useSelector((state: RootState) => state.courses.course?.expirationDate);
  const isSearchEnabled = useSelector((state: RootState) => state.courses.course?.isSearchEnabled);
  const supportedLanguages = useSelector((state: RootState) => state.courses.course?.supportedLanguages);
  const gamification = useSelector((state: RootState) => state.courses.course?.gamification);
  const lessons = useSelector((state: RootState) => state.courses.course?.lessons);
  const passingPercentage = useSelector((state: RootState) => state.courses.course?.passingPercentage);
  const isFinalQuizRepeatable = useSelector((state: RootState) => state.courses.course?.isFinalQuizRepeatable);

  const { startLoading, stopLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { lang } = useParams();

  const finalQuiz = lessons?.filter(lesson => lesson.type === LessonTypeDto.FinalQuiz);
  const hasFinalQuiz = (finalQuiz && finalQuiz.length > 0) || false;

  const defaultValues: ICourseConfigurationsFormData = {
    courseOptions: {
      title: title || '',
      description: description || '',
      validFromDate: validFromDate ? new Date(validFromDate) : null,
      expirationDate: expirationDate ? new Date(expirationDate) : null,
      isSearchEnabled: !!isSearchEnabled,
    },
    languages: {
      collection: supportedLanguages?.map(lang => ({ id: lang.language || '', value: getLanguageFromCode(lang.language || '') || '', active: !!lang.isPublishable })) || [],
      newLanguage: '',
    },
    quizOptions: {
      isFinalQuizRepeatable: !!isFinalQuizRepeatable,
      passingPercentage: passingPercentage || 0,
    },
    gamification: {
      ...gamification,
      courseCompletitionBonusPoints: !gamification?.courseCompletitionBonusPoints ? null : gamification?.courseCompletitionBonusPoints,
      oneShotExamPassingBonusPoints: !gamification?.oneShotExamPassingBonusPoints ? null : gamification?.oneShotExamPassingBonusPoints,
      courseCompletitionPoints:
        !gamification?.courseCompletitionPoints || (gamification?.courseCompletitionPoints && Object.values(gamification.courseCompletitionPoints).every(value => value === null || value === undefined || value === '')) ? null : {
        ...gamification?.courseCompletitionPoints,
        totalMaxPoints: !gamification?.courseCompletitionPoints?.totalMaxPoints ? null : gamification?.courseCompletitionPoints?.totalMaxPoints,
        minimumPoints: !gamification?.courseCompletitionPoints?.minimumPoints ? null : gamification?.courseCompletitionPoints?.minimumPoints,
        targetCompletitionDate: gamification?.courseCompletitionPoints?.targetCompletitionDate ? new Date(gamification?.courseCompletitionPoints?.targetCompletitionDate) : null,
        degradationPeriod: gamification?.courseCompletitionPoints?.degradationPeriod ? gamification.courseCompletitionPoints?.degradationPeriod.split('.')[0] : '',
        degradationPoints: gamification?.courseCompletitionPoints?.degradationPoints || null,
      }
    },
  };

  const addingNewLanguage = () => {
    setIsTransitioning(true);
    setTimeout(() => setIsTransitioning(false), 500);
  };

  const updateHeight = (key: string, height: number) => {
    setTabs(prevTabs => {
      return prevTabs.map(tab => {
        if (tab.key === key) {
          return {
            ...tab,
            height
          };
        }
        return tab;
      });
    });
  };

  const [tabs, setTabs] =  useState<IMLTab[]>([
    {
      key: 'course-options',
      label: 'Course options',
      component: <CourseOptions updateHeight={(height: number) => updateHeight('course-options', height)} />,
      ref: createRef(),
      height: 'auto'
    },
    {
      key: 'languages',
      label: 'Languages',
      component: 
        <Languages
          updateHeight={(height: number) => updateHeight('languages', height)}
          addingNewLanguage={addingNewLanguage}  
        />,
      ref: createRef(),
      height: 'auto'
    },
    {
      key: 'quiz-options',
      label: 'Quiz options',
      component: <QuizOptions updateHeight={(height: number) => updateHeight('quiz-options', height)} />,
      ref: createRef(),
      height: 'auto'
    },
    {
      key: 'gamification',
      label: 'Gamification',
      component: <Gamification updateHeight={(height: number) => updateHeight('gamification', height)} />,
      ref: createRef(),
      height: 'auto'
    },
  ]);

  const compareData = (values: ICourseConfigurationsFormData) => {
    return (
      !_.isEqual(values.courseOptions, defaultValues.courseOptions) ||
      !_.isEqual(values.quizOptions, defaultValues.quizOptions) ||
      !_.isEqual(values.languages.collection, defaultValues.languages.collection) ||
      !_.isEqual(values.gamification, defaultValues.gamification)
    );
  };

  const onSubmit: SubmitHandler<ICourseConfigurationsFormData> = async (data, e) => {
    console.log('settings data', data);

    let courseCompletitionPoints;
    if (data.gamification.courseCompletitionPoints) {
      if (Object.values(data.gamification.courseCompletitionPoints).every(value => value === null)) {
        courseCompletitionPoints = null;
      } else {
        courseCompletitionPoints = {
          totalMaxPoints: 
              data.gamification?.courseCompletitionPoints?.totalMaxPoints === null ||
              data.gamification?.courseCompletitionPoints?.totalMaxPoints === undefined ?
                undefined :
                data.gamification.courseCompletitionPoints.totalMaxPoints,
            minimumPoints:
              data.gamification?.courseCompletitionPoints?.minimumPoints === null ||
              data.gamification?.courseCompletitionPoints?.minimumPoints === undefined ?
                undefined :
                data.gamification.courseCompletitionPoints.minimumPoints,
            targetCompletitionDate:
              data.gamification?.courseCompletitionPoints?.targetCompletitionDate === null ||
              data.gamification?.courseCompletitionPoints?.targetCompletitionDate === undefined ?
                undefined :
                DateTime.fromJSDate(data.gamification.courseCompletitionPoints?.targetCompletitionDate).toISODate() || undefined,
            degradationPeriod: !data.gamification.courseCompletitionPoints?.degradationPeriod ? null : `${Duration.fromObject({ days: parseInt(data.gamification.courseCompletitionPoints?.degradationPeriod) }).as('days')}.00:00:00`,
            degradationPoints: data.gamification.courseCompletitionPoints?.degradationPoints || null,
        };
      }
    } else courseCompletitionPoints = null;
    

    try {
      let reroute = false;
      const promiseArray = [];
      const saveCourseBody = {
        courseOptions: {
          ...data.courseOptions,
          expirationDate: data.courseOptions.expirationDate ? DateTime.fromJSDate(data.courseOptions.expirationDate).toISODate() : null,
          validFromDate: data.courseOptions.validFromDate ? DateTime.fromJSDate(data.courseOptions.validFromDate).toISODate() : null,
        },
        quizOptions: {
          ...data.quizOptions,
          passingPercentage: Number(data.quizOptions.passingPercentage),
        },
        gamification: {
          ...data.gamification,
          courseCompletitionPoints: courseCompletitionPoints === null ? null : { ...courseCompletitionPoints },
        },
        lang,
      };
      
      if (
        !_.isEqual(saveCourseBody.courseOptions, defaultValues.courseOptions) ||
        !_.isEqual(saveCourseBody.quizOptions, defaultValues.quizOptions) ||
        !_.isEqual(saveCourseBody.gamification, defaultValues.gamification)
      ) {
        promiseArray.push(() => dispatch(saveCourseOptions(saveCourseBody)));
      }

      if (!_.isEqual(data.languages.collection, defaultValues.languages.collection)) {
        const languageCollectionBody: SupportedLanguageDto[] = 
        data.languages.collection.map(lang => {
        const existingLang = supportedLanguages?.find(supportedLang => supportedLang.language === lang.id);
          if (existingLang) return {
            ...existingLang,
            isPublishable: lang.active,
          }
          else return {
            language: lang.id,
            isPublishable: lang.active,
            status: LanguageStatusDto.Draft,
            publishedUrl: null,
          }
        });
        promiseArray.push(() => dispatch(saveLanguageCollection(languageCollectionBody)));
        reroute = true;
      }

      await Promise.all(promiseArray.map(dispatchFn => dispatchFn()));
      if (reroute) {
        onDismiss();
        startLoading('Redirecting to the learnings page...');
        await timeout(6000);
        // await dispatch(getCourses()).unwrap();
        stopLoading();
        navigate('/learnings');
      } else onDismiss();
      
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <MLModal
      show={show}
      onDismiss={onDismiss}
      width="55rem"
      isTabForm
    >
      <MLTabForm
        title='Configurations'
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        tabs={hasFinalQuiz ? tabs : tabs.filter(tab => tab.key !== 'quiz-options')}
        compareData={compareData}
        isTransitioning={isTransitioning}
      />
    </MLModal>
  );
};

export default CourseConfigurations;